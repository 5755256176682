import React, { useState } from 'react';
import PropTypes from 'prop-types';

import ErrorBoundaryDecorator from '@components/decorators/ErrorBoundaryDecorator';
import Spinner from '@jsv3/components/molecules/Spinner';
import Button from '@jsv3/components/atoms/Button';
import QuestionsForm from './QuestionsForm';
import { QUESTION_TYPES } from '../config';

const REQUIRED_ERROR_MESSAGE = 'Question is required';

/**
 * The component for displaying questions one by one with Image.
 *
 * @param {array} questions
 * @param {string} error
 * @param {boolean} isMocked
 * @param {object} values
 * @param {object} fieldsErrors
 * @param {function} handleSubmit
 * @param {function} uploadFile
 * @param {boolean} isFileLoading
 * @param {string} title
 * @param {string} description
 * @return {JSX.Element}
 * @constructor
 */
const OneByOneScreenWithImageComponent = ({
  questions,
  error,
  isMocked,
  values,
  fieldsErrors,
  handleSubmit,
  uploadFile,
  isFileLoading,
  title,
  description,
}) => {
  const [activeQuestion, setActiveQuestion] = useState(questions[0]);
  const [isLastQuestion, setIsLastQuestion] = useState(questions.length === 1);
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [answerError, setAnswerError] = useState('');

  /**
   * @param item
   * @return {string}
   */
  const setQuestionClass = (item) => {
    if (item.id === activeQuestion.id) {
      return ' one-with-image-type__active-question';
    }

    return ' one-with-image-type__hidden-question';
  };

  /**
   * @return {boolean}
   */
  const shouldBlockNavigation = () => {
    const activeQuestionIndex = questions.findIndex(
      (question) => question.id === activeQuestion.id,
    );

    return (
      activeQuestion.is_required &&
      values.answers[activeQuestionIndex].answers.length === 0 &&
      activeQuestion.type !== QUESTION_TYPES.DUMMY
    );
  };

  /**
   * @param {number} index
   */
  const handlerNextQuestion = (index) => {
    if (shouldBlockNavigation()) {
      setAnswerError(REQUIRED_ERROR_MESSAGE);
    } else {
      setAnswerError('');

      const nextQuestionIndex = index + 1;

      if (nextQuestionIndex === questions.length - 1) {
        setIsLastQuestion(true);
      }

      setActiveQuestion(questions[nextQuestionIndex]);
      setCurrentQuestionIndex(nextQuestionIndex);
    }
  };

  /**
   * @param data
   */
  const onHandleSubmit = (data) => {
    if (shouldBlockNavigation()) {
      setAnswerError(REQUIRED_ERROR_MESSAGE);
    } else {
      handleSubmit(data);
    }
  };

  /**
   * @param {number} index
   */
  const goToQuestionByIndex = (index) => {
    if (index < currentQuestionIndex) {
      setActiveQuestion(questions[index]);
      setCurrentQuestionIndex(index);
    }
  };

  /**
   * @param {object} item
   * @param {number} index
   * @return {JSX.Element|null}
   */
  const renderQuestionActions = (item, index) => {
    if (!isLastQuestion) {
      return (
        <Button
          className="btn-red btn-rounded w-100"
          type="button"
          disabled={isFileLoading}
          data-qa-id="next_question"
          onClick={() => handlerNextQuestion(index)}
        >
          {item.button || 'next'}

          {isFileLoading && <Spinner color="white" inline width="20px" />}
        </Button>
      );
    }

    return null;
  };

  /**
   * @return {JSX.Element|null}
   */
  const renderFormAction = () => {
    if (isLastQuestion) {
      return (
        <div className="quiz-wrapper quiz-wrapper--btn relative">
          <div className="btn-wrapper-center mt-0">
            <Button
              className="btn-red btn-rounded w-100"
              type="submit"
              data-qa-id="submit"
              disabled={isMocked || isFileLoading}
              onClick={onHandleSubmit}
            >
              SUBMIT
              {isFileLoading && <Spinner color="white" inline width="20px" />}
            </Button>
          </div>
        </div>
      );
    }

    return null;
  };

  return (
    <div className="one-with-image-type">
      <div className="one-with-image-type__wrapper">
        {title && (
          <h1 className="one-with-image-type__heading" data-qa-id="title-message">
            {title}
          </h1>
        )}

        {description && (
          <p className="one-with-image-type__description" data-qa-id="description-message">
            {description}
          </p>
        )}

        <QuestionsForm
          contentClassNames="questionnaire form"
          questions={questions}
          error={error || answerError}
          values={values}
          fieldsErrors={fieldsErrors}
          getQuestionActions={renderQuestionActions}
          getFormActions={renderFormAction}
          setQuestionClass={setQuestionClass}
          uploadFile={uploadFile}
          showQuestionImage
        />

        <div className="one-with-image-type__progress">
          {questions.map((item, index) => (
            <button
              key={item.id}
              type="button"
              className={`one-with-image-type__progress-item${
                index <= currentQuestionIndex ? ' filled' : ''
              }`}
              onClick={() => goToQuestionByIndex(index)}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

OneByOneScreenWithImageComponent.propTypes = {
  questions: PropTypes.arrayOf(PropTypes.object).isRequired,
  error: PropTypes.string,
  isMocked: PropTypes.bool,
  handleSubmit: PropTypes.func.isRequired,
  values: PropTypes.object.isRequired,
  uploadFile: PropTypes.func.isRequired,
  fieldsErrors: PropTypes.object,
  isFileLoading: PropTypes.bool,
  title: PropTypes.string,
  description: PropTypes.string,
};

OneByOneScreenWithImageComponent.defaultProps = {
  error: '',
  isMocked: false,
  fieldsErrors: {},
  isFileLoading: false,
  title: '',
  description: '',
};

export default ErrorBoundaryDecorator()(OneByOneScreenWithImageComponent);
