import React, { useState } from 'react';
import { Formik } from 'formik';
import PropTypes from 'prop-types';

import ErrorBoundaryDecorator from '@components/decorators/ErrorBoundaryDecorator';
import apiClient from '@js/apiClient';
import { uploadFileDataRequest } from '@jsv3/utils/api/fileUploaderAPI';
import OnOneScreenComponent from './UI/OnOneScreenComponent';
import OneByOneScreenComponent from './UI/OneByOneScreenComponent';
import OneByOneScreenWithImageComponent from './UI/OneByOneScreenWithImageComponent';
import { SURVEY_DISPLAY_TYPES, QUESTION_TYPES } from './config';

const component = {
  [SURVEY_DISPLAY_TYPES.ON_ONE_SCREEN]: (props) => <OnOneScreenComponent {...props} />,
  [SURVEY_DISPLAY_TYPES.ONE_BY_ONE]: (props) => <OneByOneScreenComponent {...props} />,
  [SURVEY_DISPLAY_TYPES.ONE_BY_ONE_WITH_IMAGE]: (props) => (
    <OneByOneScreenWithImageComponent {...props} />
  ),
};

/**
 * @param {function} performRequest
 * @param {array} questions
 * @param {function} afterSubmit
 * @param {string} error
 * @param {boolean} isMocked
 * @param {boolean} isPreview
 * @param {function} submitApiRequest
 * @param {string} submitApiMethod
 * @param {object} apiRequestParams
 * @param {boolean} scrollAfterSubmit
 * @param {string} displayType
 * @param {string} title
 * @param {string} description
 * @param {string} classes
 * @return {JSX.Element}
 * @constructor
 */
const QuestionnaireWrapper = ({
  performRequest,
  questions,
  afterSubmit,
  error,
  isMocked,
  isPreview,
  submitApiRequest,
  submitApiMethod,
  apiRequestParams,
  scrollAfterSubmit,
  displayType,
  title,
  description,
  classes,
}) => {
  const [fieldsErrors, setFieldsErrors] = useState({});
  const [isFileLoading, setIsFileLoading] = useState(false);

  /**
   * @param {number} questionId
   * @param {string} errorMessage
   */
  const setFieldsErrorsHandler = (questionId, errorMessage) => {
    setFieldsErrors((prevState) => ({
      ...prevState,
      [questionId]: errorMessage,
    }));
  };

  /**
   * @param {object} data
   * @return {boolean}
   */
  const validateData = (data) => {
    let result = true;

    if (!data.answers) {
      return false;
    }

    const emptyAnswers = data.answers.filter(
      (question) =>
        question.is_required &&
        question.answers.length === 0 &&
        question.type !== QUESTION_TYPES.DUMMY,
    );

    if (emptyAnswers.length > 0) {
      emptyAnswers.forEach((question) => {
        setFieldsErrorsHandler(question.question_id, 'Question is required');
      });

      result = false;
    }

    return result;
  };

  /**
   * @param {array} values
   */
  const handleSubmit = (values) => {
    setFieldsErrors({});

    if (isMocked) {
      return;
    }

    if (isPreview) {
      afterSubmit(false);

      return;
    }

    if (!validateData(values)) {
      return;
    }

    if (scrollAfterSubmit) {
      window.scrollTo(0, 0);
    }

    if (!submitApiRequest) {
      afterSubmit(values);

      return;
    }

    const submitFunc = () => apiClient[submitApiMethod](submitApiRequest, values);

    performRequest(submitFunc).then(() => {
      afterSubmit(false);
    });
  };

  /**
   * @return {{}}
   */
  const setInitialValues = () => {
    let initialValues = {};
    const answers = [];

    questions.forEach((item) => {
      answers.push({
        question_id: item.id,
        comment: '',
        answers: [],
        is_required: item.is_required,
        type: item.type,
      });
    });

    initialValues.answers = answers;

    if (apiRequestParams) {
      initialValues = { ...initialValues, ...apiRequestParams };
    }

    return initialValues;
  };

  /**
   * @param {formData} formData
   * @param {number} questionId
   */
  const onUploadFile = (formData, questionId) => {
    setIsFileLoading(true);

    return uploadFileDataRequest(formData)
      .catch((e) => {
        setFieldsErrorsHandler(questionId, e.response.data.message);
      })
      .finally(() => setIsFileLoading(false));
  };

  return (
    <Formik initialValues={setInitialValues()} onSubmit={handleSubmit}>
      {(props) =>
        component[displayType]({
          questions,
          error,
          isMocked,
          handleSubmit,
          values: props.values,
          fieldsErrors,
          title,
          description,
          uploadFile: onUploadFile,
          classes,
          isFileLoading,
        })
      }
    </Formik>
  );
};

QuestionnaireWrapper.propTypes = {
  questions: PropTypes.arrayOf(PropTypes.object).isRequired,
  submitApiRequest: PropTypes.string,
  performRequest: PropTypes.func,
  error: PropTypes.string,
  afterSubmit: PropTypes.func,
  isMocked: PropTypes.bool,
  isPreview: PropTypes.bool,
  apiRequestParams: PropTypes.object,
  scrollAfterSubmit: PropTypes.bool,
  displayType: PropTypes.string,
  title: PropTypes.string,
  description: PropTypes.string,
  submitApiMethod: PropTypes.string,
  classes: PropTypes.string,
};

QuestionnaireWrapper.defaultProps = {
  error: '',
  submitApiRequest: '',
  afterSubmit: () => {},
  performRequest: () => {},
  isMocked: false,
  isPreview: false,
  apiRequestParams: null,
  scrollAfterSubmit: true,
  displayType: SURVEY_DISPLAY_TYPES.ON_ONE_SCREEN,
  title: '',
  description: '',
  submitApiMethod: 'post',
  classes: '',
};

export default ErrorBoundaryDecorator()(QuestionnaireWrapper);
