import React from 'react';

import LinkItem from '../../components/LinkItem';

const Trips = ({ onMenuItemClick }) => (
  <LinkItem
    href="/profile#/my-trips"
    id="account-orders-button"
    title="My bookings"
    onMenuItemClick={onMenuItemClick}
  />
);

export default Trips;
