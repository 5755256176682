import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import processEmptyProp from '@jsv3/utils/helpers/processEmptyPropHelper';

/**
 * Atom <h2> for Layovers
 */
const LayoverTitle = ({ className, isUnderline, dataQaId, children, ...props }) => (
  <h2
    className={classNames('layover-title', { 'layover-title--underline': isUnderline }, className)}
    {...props}
    {...processEmptyProp('data-qa-id', dataQaId)}
  >
    {children}
  </h2>
);

LayoverTitle.propTypes = {
  className: PropTypes.string,
  dataQaId: PropTypes.string,
  isUnderline: PropTypes.bool,
};

LayoverTitle.defaultProps = {
  className: '',
  dataQaId: null,
  isUnderline: false,
};

export default LayoverTitle;
