import React from 'react';
import PropTypes from 'prop-types';

import ErrorBoundaryDecorator from '@components/decorators/ErrorBoundaryDecorator';
import Spinner from '@jsv3/components/molecules/Spinner';
import QuestionsForm from './QuestionsForm';

/**
 * The component to display all questions on one screen.
 *
 * @param {array} questions
 * @param {string} error
 * @param {boolean} isMocked
 * @param {object} values
 * @param {object} fieldsErrors
 * @param {function} handleSubmit
 * @param {function} uploadFile
 * @param {string} title
 * @param {string} description
 * @param {string} classes
 * @param {boolean} isFileLoading
 * @return {JSX.Element}
 * @constructor
 */
const OnOneScreenComponent = ({
  questions,
  error,
  isMocked,
  values,
  fieldsErrors,
  handleSubmit,
  uploadFile,
  title,
  description,
  classes,
  isFileLoading,
}) => {
  const renderFormAction = () => (
    <div className="quiz-wrapper quiz-wrapper--btn">
      <div className="btn-wrapper-center">
        <button
          className="btn-survey"
          type="submit"
          data-qa-id="save"
          disabled={isMocked || isFileLoading}
          onClick={handleSubmit}
        >
          SUBMIT
          {isFileLoading && <Spinner color="white" inline width="20px" />}
        </button>
      </div>
    </div>
  );

  return (
    <div className={`pt-40 p-30 background-light-gray ${classes}`}>
      {title && (
        <h1 className="questionnaire-type-heading" data-qa-id="title-message">
          {title}
        </h1>
      )}

      {description && (
        <p className="questionnaire-type-description" data-qa-id="description-message">
          {description}
        </p>
      )}

      <QuestionsForm
        questions={questions}
        classNames="questionnaire questionnaire-type-page one-on-screen-type form"
        error={error}
        values={values}
        fieldsErrors={fieldsErrors}
        getFormActions={renderFormAction}
        uploadFile={uploadFile}
        isFileLoading={isFileLoading}
      />
    </div>
  );
};

OnOneScreenComponent.propTypes = {
  questions: PropTypes.arrayOf(PropTypes.object).isRequired,
  error: PropTypes.string,
  isMocked: PropTypes.bool,
  handleSubmit: PropTypes.func.isRequired,
  values: PropTypes.object.isRequired,
  fieldsErrors: PropTypes.object,
  title: PropTypes.string,
  description: PropTypes.string,
  classes: PropTypes.string,
  uploadFile: PropTypes.func.isRequired,
  isFileLoading: PropTypes.bool,
};

OnOneScreenComponent.defaultProps = {
  error: '',
  isMocked: false,
  fieldsErrors: {},
  title: '',
  description: '',
  classes: '',
  isFileLoading: false,
};

export default ErrorBoundaryDecorator()(OnOneScreenComponent);
