import React, { useState } from 'react';
import Expand from 'react-expand-animated';

const PaymentInformationMessage = () => {
  const [warning, toggleWarning] = useState(false);

  return (
    <>
      <div className="sub-title-popup">
        {t('auth.payment_warning.title')}

        {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
        <span className="button-link" onClick={() => toggleWarning(!warning)}>
          {` ${t('auth.payment_warning.toggle_text')}?`}
        </span>
      </div>

      <Expand open={warning} duration={500} transitions={['height', 'opacity']}>
        <div className="warning-text">
          We will use this information to avoid interruptions in your membership. Your credit card information
          is stored not in our system, rather it is saved to our certified and PCI/DSS compliant payment
          processor's vault over a secure channel. You will have an option to cancel anytime, no questions asked.
        </div>
      </Expand>
    </>
  );
};

export default PaymentInformationMessage;
