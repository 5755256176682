import React from 'react';
import PropTypes from 'prop-types';

import ErrorBoundaryDecorator from '@components/decorators/ErrorBoundaryDecorator';
import ParserWithFaqsOptions from '@jsv2/config/faqsParserOptions';

const Faqs = ({ isNewFormat, code }) => (
  <div className={`faq-organism${isNewFormat ? ' faq-organism--new-format' : ''}`}>
    {ParserWithFaqsOptions(code)}
  </div>
);

Faqs.propTypes = {
  isNewFormat: PropTypes.bool,
};

Faqs.defaultProps = {
  isNewFormat: false,
};

export default ErrorBoundaryDecorator()(Faqs);
