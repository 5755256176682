import React from 'react';
import PropTypes from 'prop-types';
import { getLinkToGo, getBackLinkToGo } from '@jsv3/utils/urlUtils';
import ErrorBoundaryDecorator from '@components/decorators/ErrorBoundaryDecorator';

const Logo = ({ color }) => {
  const createLogoLink = () => {
    return `${getLinkToGo()}&go_back_url=${getBackLinkToGo()}`;
  };

  return (
    <a href={createLogoLink()} id="logo-link" className="main-header__logotype">
      <img src={`/images/lp/promo2/logotype-${color}.svg`} alt="VIP Traveler" />
    </a>
  );
};

Logo.propTypes = {
  color: PropTypes.string,
};

Logo.defaultProps = {
  color: 'black',
};

export default ErrorBoundaryDecorator()(Logo);
