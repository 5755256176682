import apiClient from '@jsv3/services/apiClient';

/**
 * Get a list of reviews.
 *
 * @param params
 * @return {Promise<AxiosResponse<any>>}
 */
export function getReviewListApi(params = {}) {
  return apiClient.get('reviews', { params });
}
