import React, { useState } from 'react';
import PropTypes from 'prop-types';

import Image from '@jsv2/components/Image';
import { IMAGE_SIZES } from '@js/constants';
import ErrorBoundaryDecorator from '@components/decorators/ErrorBoundaryDecorator';
import Spinner from '@jsv3/components/molecules/Spinner';
import { QUESTION_TYPES } from '../config';
import QuestionsForm from './QuestionsForm';

/**
 * The component for displaying questions one by one.
 *
 * @param {array} questions
 * @param {string} error
 * @param {boolean} isMocked
 * @param {object} values
 * @param {object} fieldsErrors
 * @param {function} handleSubmit
 * @param {function} uploadFile
 * @param {boolean} isFileLoading
 * @param {string} title
 * @param {string} description
 * @return {JSX.Element}
 * @constructor
 */
const OneByOneScreenComponent = ({
  questions,
  error,
  isMocked,
  values,
  fieldsErrors,
  handleSubmit,
  uploadFile,
  isFileLoading,
  title,
  description,
}) => {
  const [activeQuestion, setActiveQuestion] = useState(questions[0]);
  const [isLastQuestion, setIsLastQuestion] = useState(questions.length === 1);
  const [questionBackgroundImage, setQuestionBackgroundImage] = useState(questions[0].image);

  /**
   * @param item
   * @return {string}
   */
  const setQuestionClass = (item) => {
    if (item.id === activeQuestion.id) {
      return ' one-by-one-type__active-question';
    }

    return ' one-by-one-type__hidden-question';
  };

  /**
   * @return {boolean}
   */
  const isButtonDisabled = () => {
    const activeQuestionIndex = questions.findIndex(
      (question) => question.id === activeQuestion.id,
    );

    return (
      activeQuestion.is_required &&
      values.answers[activeQuestionIndex].answers.length === 0 &&
      activeQuestion.type !== QUESTION_TYPES.DUMMY
    );
  };

  /**
   * @param {number} index
   */
  const handlerNextQuestion = (index) => {
    const nextQuestionIndex = index + 1;

    if (nextQuestionIndex === questions.length - 1) {
      setIsLastQuestion(true);
    }

    setActiveQuestion(questions[nextQuestionIndex]);
    setQuestionBackgroundImage(questions[nextQuestionIndex].image);
  };

  /**
   * @param {object} item
   * @param {number} index
   * @return {JSX.Element|null}
   */
  const renderQuestionActions = (item, index) => {
    if (!isLastQuestion) {
      return (
        <button
          className="btn-survey mt-50 btn-inner-survey"
          type="button"
          disabled={isButtonDisabled() || isFileLoading}
          data-qa-id="next_question"
          onClick={() => handlerNextQuestion(index)}
        >
          {item.button || 'next'}

          {isFileLoading && <Spinner color="white" inline width="20px" />}
        </button>
      );
    }

    return null;
  };

  /**
   * @return {JSX.Element|null}
   */
  const renderFormAction = () => {
    if (isLastQuestion) {
      return (
        <div className="quiz-wrapper quiz-wrapper--btn relative">
          <div className="btn-wrapper-center mt-0">
            <button
              className="btn-survey mt-0"
              type="submit"
              data-qa-id="submit"
              disabled={isMocked || isButtonDisabled() || isFileLoading}
              onClick={handleSubmit}
            >
              SUBMIT
              {isFileLoading && <Spinner color="white" inline width="20px" />}
            </button>
          </div>
        </div>
      );
    }

    return null;
  };

  /**
   * @return {boolean}
   */
  const hasBackground = () =>
    !!(questionBackgroundImage && Object.keys(questionBackgroundImage).length > 0);

  return (
    <Image
      className={`quiz-promo-2 one-by-one-type${!hasBackground() ? ' no-background' : ''}`}
      data={questionBackgroundImage}
      config={{
        size: IMAGE_SIZES.LARGE,
      }}
      background={hasBackground()}
    >
      <div className="one-by-one-type__wrapper">
        {title && (
          <h1 className="one-by-one-type__heading" data-qa-id="title-message">
            {title}
          </h1>
        )}

        {description && (
          <p className="one-by-one-type__description" data-qa-id="description-message">
            {description}
          </p>
        )}

        <QuestionsForm
          contentClassNames="questionnaire form"
          questions={questions}
          error={error}
          values={values}
          fieldsErrors={fieldsErrors}
          getQuestionActions={renderQuestionActions}
          getFormActions={renderFormAction}
          setQuestionClass={setQuestionClass}
          uploadFile={uploadFile}
        />
      </div>
    </Image>
  );
};

OneByOneScreenComponent.propTypes = {
  questions: PropTypes.arrayOf(PropTypes.object).isRequired,
  error: PropTypes.string,
  isMocked: PropTypes.bool,
  handleSubmit: PropTypes.func.isRequired,
  values: PropTypes.object.isRequired,
  uploadFile: PropTypes.func.isRequired,
  fieldsErrors: PropTypes.object,
  isFileLoading: PropTypes.bool,
  title: PropTypes.string,
  description: PropTypes.string,
};

OneByOneScreenComponent.defaultProps = {
  error: '',
  isMocked: false,
  fieldsErrors: {},
  isFileLoading: false,
  title: '',
  description: '',
};

export default ErrorBoundaryDecorator()(OneByOneScreenComponent);
