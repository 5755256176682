export const SURVEY_DISPLAY_TYPES = {
  ON_ONE_SCREEN: 'all-on-one-screen',
  ONE_BY_ONE: 'one-by-one',
  ONE_BY_ONE_WITH_IMAGE: 'one-by-one-with-image',
};

export const IMAGE_TYPES = ['image/jpeg', 'image/png'];

export const ALLOWED_UPLOAD_QUESTION_FILE_TYPES = [
  'application/docx',
  'application/pdf',
  '.docx',
  '.pdf',
  '.doc',
  '.txt',
  ...IMAGE_TYPES,
];

export const QUESTION_TYPES = {
  TEXT: 'text',
  TEXT_MULTIPLE: 'text-multiple',
  SELECT: 'select',
  SELECT_MULTIPLE: 'select-multiple',
  UPLOAD: 'upload',
  DUMMY: 'dummy',
};
