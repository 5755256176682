import React, { useEffect, useState } from 'react';
import { Formik, Form, Field } from 'formik';

import ErrorBoundaryDecorator from '@components/decorators/ErrorBoundaryDecorator';
import apiClient from '@js/apiClient';
import { isFullyVerified } from '@jsv2/utils/UserUtils/userStatuses';
import parseFormErrors from '@js/utils/form/parseFormErrors';
import OverlaySpinner from '@js/components/OverlaySpinner';
import { getCountriesList } from '@jsv2/utils/CountryUtils/APIRequests';
import PhoneInputAdapter from '@components/formComponents/PhoneFormikAdapter';
import Modal from '@components/common/Modal';
import CustomField from '@js/components/formComponents/CustomField';
import LabelComponent from '@js/landing/vipTravelerPromo2/auth/LabelComponent';
import LayoverTitle from '@jsv3/components/atoms/LayoverTitle';
import Button from '@jsv3/components/atoms/Button';

const { user, userRoles } = window;

const ContactUsModal = ({ isModalOpened, onClose }) => {
  const [countries, setCountries] = useState([]);
  const [isFormSubmitted, setIsFormSubmitted] = useState(false);

  const initialValues = {
    name: user && user.full_name ? user.full_name : '',
    phone: user && user.phone ? user.phone : '',
    email: user && user.email && isFullyVerified(userRoles) ? user.email : '',
    message: '',
  };

  useEffect(() => {
    if (isFormSubmitted) {
      setIsFormSubmitted(false);
    }
  }, [isModalOpened]);

  useEffect(() => {
    getCountriesList()
      .then((resp) => {
        if (resp && resp.data) {
          setCountries(resp.data.data.map((c) => c.iso2.toLowerCase()));
        }
      })
      .catch((err) => {
        Logger.error(err);
      });
  }, []);

  const submitForm = (values, { setErrors, setSubmitting }) => {
    setSubmitting(true);

    return apiClient
      .post('/contact', {
        ...values,
      })
      .then(() => {
        setSubmitting(false);
        setIsFormSubmitted(true);
      })
      .catch((error) => {
        setSubmitting(false);

        if (error.response) {
          setErrors(parseFormErrors(error.response.data.data));
        }
      });
  };

  return (
    <Modal
      isOpen={isModalOpened}
      withCloseButton
      onRequestClose={onClose}
      shouldCloseOnOverlayClick
      isOverlay
      data={{
        'qa-id': `qa_request_modal_call_back`,
      }}
    >
      <div className="lp-contact-form">
        {isFormSubmitted ? (
          <>
            <LayoverTitle dataQaId="request_modal_title_thanks" className="mb-10">
              Thank you!
            </LayoverTitle>

            <p className="text-center mb-20">
              We look forward to reviewing your enquiry, and hope to get back to you real soon.
            </p>

            <Button
              className="btn-red btn-rounded"
              data-qa-id="ok_btn"
              type="button"
              onClick={onClose}
            >
              OK
            </Button>
          </>
        ) : (
          <Formik initialValues={initialValues} onSubmit={submitForm} validateOnChange={false}>
            {({ isSubmitting, errors }) => (
              <>
                {isSubmitting && <OverlaySpinner />}

                <LayoverTitle dataQaId="request_modal_title" className="mb-5">
                  Request a Call Back
                </LayoverTitle>

                <p className="text-center mb-20">Any question big or small, we’re here for you.</p>

                <Form>
                  <div className="contact-form form" data-qa-id="contact_form">
                    <div className="input form-field">
                      <LabelComponent name="name" errors={errors} labelText="Name" />

                      <CustomField
                        type="text"
                        name="name"
                        placeholder="Enter Name"
                        data-qa-id="name_input"
                      />
                    </div>

                    <div className="input form-field" data-qa-id="phone_input">
                      <LabelComponent name="phone" errors={errors} labelText="Phone" />

                      <CustomField
                        name="phone"
                        country="us"
                        enableSearchField
                        component={PhoneInputAdapter}
                        onlyCountries={countries}
                        inputExtraProps={{
                          id: 'phone-input',
                          name: 'phone',
                          'data-qa-id': 'phone_field',
                        }}
                      />
                    </div>

                    <div className="input form-field">
                      <LabelComponent name="email" errors={errors} labelText="Email" />

                      <Field
                        type="email"
                        name="email"
                        autoComplete="email"
                        placeholder="Email Address"
                        data-qa-id="email_input"
                      />
                    </div>

                    <div className="input form-field">
                      <LabelComponent name="message" errors={errors} labelText="Message" />

                      <CustomField
                        component="textarea"
                        name="message"
                        data-qa-id="message_input"
                        placeholder="Enter Text"
                      />
                    </div>

                    <div className="btn-wrapper">
                      <Button className="btn-red btn-rounded" data-qa-id="submit_btn" type="submit">
                        Submit
                      </Button>
                    </div>
                  </div>
                </Form>
              </>
            )}
          </Formik>
        )}
      </div>
    </Modal>
  );
};
export default ErrorBoundaryDecorator()(ContactUsModal);
