import React from 'react';
import Parser from 'html-react-parser';

import Accordion from '@jsv3/components/organisms/Accordion';
import Faqs from '@jsv3/components/organisms/Faqs';

const { faqs } = window;

const clearFaqRegex = /^\[faq id="([^\]]*)"]$/;
const dirtyFaqRegex = /\[faq id="([^\]]*)"]/;

/**
 * faqs parser options.
 *
 * @return {Object}
 */
export const faqsParserOptions = (isNewStyle = false) => {
  if (!faqs) {
    return {};
  }

  const createFaq = (data, key = 0) => {
    const [shortCode, faqKey] = clearFaqRegex.exec(data);

    if (!faqs[faqKey]) {
      return (<span className="label-content">FAQ category not found: {shortCode}</span>);
    }

    return isNewStyle
      ? <Faqs code={shortCode} isNewFormat />
      : <Accordion key={faqKey + key} faqs={faqs[faqKey]} />;
  };

  const createText = (data, key) => <React.Fragment key={key}>{data}</React.Fragment>;

  return {
    replace: ({ data }) => {
      if (!data) {
        return '';
      }

      if (clearFaqRegex.test(data)) {
        return createFaq(data);
      }

      if (dirtyFaqRegex.test(data)) {
        const replaced = data.replace(new RegExp(dirtyFaqRegex, 'g'), (text) => `___${text}___`);

        const items = replaced
          .split('___')
          .filter((item) => item?.length > 0)
          .map((content, index) => {
            if (clearFaqRegex.test(content)) {
              return createFaq(content, index);
            }

            return createText(content, index);
          });

        return <>{items}</>;
      }

      return '';
    },
  };
};

const ParserWithFaqsOptions = (content, isNewStyle) => Parser(content, faqsParserOptions(isNewStyle));

export default ParserWithFaqsOptions;
