import React, { useContext } from 'react';
import PropTypes from 'prop-types';

import EditFieldContext, { Editable } from '@jsv2/LandingPage/context/EditFieldContext';
import ErrorBoundaryDecorator from '@components/decorators/ErrorBoundaryDecorator';

const FeatureCard = ({ title, description, index }) => {
  const { editableFields } = useContext(EditFieldContext);

  return (
    <div className="features-grid__card">
      <div className={`features-grid__card-icon features-grid__card-icon--${index}`} />
      {editableFields && (
        <>
          <h3 className="calluna-font">
            <Editable fieldValue={title} />
          </h3>

          <p className="common-text">
            <Editable fieldValue={description} />
          </p>
        </>
      )}
    </div>
  );
};

FeatureCard.propTypes = {
  index: PropTypes.number.isRequired,
  title: PropTypes.object.isRequired,
  description: PropTypes.object.isRequired,
};
export default ErrorBoundaryDecorator()(FeatureCard);
