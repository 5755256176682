import React from 'react';
import PropTypes from 'prop-types';

import ErrorBoundaryDecorator from '@components/decorators/ErrorBoundaryDecorator';

const FullWidthImage = ({ id, backgroundImage }) => (
  <section
    id={id}
    className="full-width-image"
    style={{
      backgroundImage: `url(${backgroundImage})`,
    }}
  />
);

FullWidthImage.propTypes = {
  id: PropTypes.string.isRequired,
  backgroundImage: PropTypes.string,
};

FullWidthImage.defaultProps = {
  backgroundImage: '/images/lp/plan-a-trip/pla-a-trip-full-width-image.png',
};

export default ErrorBoundaryDecorator()(FullWidthImage);
