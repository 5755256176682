import React, { useContext } from 'react';
import PropTypes from 'prop-types';

import ErrorBoundaryDecorator from '@components/decorators/ErrorBoundaryDecorator';
import EditFieldContext, { Editable } from '@jsv2/LandingPage/context/EditFieldContext';

const SimpleBanner = React.forwardRef(
  ({ id, backgroundImage, className, isShort, hideOverlay }, ref) => {
    const { editableFields } = useContext(EditFieldContext);

    return (
      editableFields && (
        <section
          id={id}
          ref={ref}
          className={`simple-banner pt-50 pb-50 ${className}${
            isShort ? ' simple-banner--short' : ''
          }${!hideOverlay ? ' simple-banner--overlay' : ''}`}
          style={{
            backgroundImage: `url(${backgroundImage})`,
          }}
        >
          <div className="small-container">
            <div className="section-title section-title--white section-title--center mb-0">
              <Editable fieldValue={editableFields.simple_banner_heading} />
            </div>
          </div>
        </section>
      )
    );
  },
);

SimpleBanner.propTypes = {
  id: PropTypes.string.isRequired,
  backgroundImage: PropTypes.string,
  className: PropTypes.string,
  isShort: PropTypes.bool,
  hideOverlay: PropTypes.bool,
};

SimpleBanner.defaultProps = {
  backgroundImage: '/images/lp/simple-banner.jpg',
  className: '',
  isShort: false,
  hideOverlay: false,
};

export default ErrorBoundaryDecorator()(SimpleBanner);
