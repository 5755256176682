import ErrorBoundaryDecorator from '@components/decorators/ErrorBoundaryDecorator';
import PROFILER_FLOWS from '../Profiler/flows';

/**
 * List of available sign-up flows. Should be identical to codes stored in the database.
 *
 * @type {Object|(string)[]}
 */
const SIGNUP_FLOW = {
  PAID: 'paid_flow',
  FREE: 'free_flow',
  UPGRADE: 'upgrade_flow',
  WELCOME: 'welcome_flow',
  WELCOME_MULTIPLE: 'welcome_flow_multiple',
  WELCOME_UPDATED: 'welcome_flow_updated',
  PROFILER_PAID: 'profiler_paid_flow',
  PROFILER_UPGRADE: 'profiler_upgrade_flow',
  DISABLED: 'disabled_flow',
  UPGRADE_PLAN: 'upgrade_plan',
  ONE_OF_BOOKING: 'one_of_booking_flow-old',
  DEPOSIT: 'one_of_booking_flow',
  DEPOSIT_FLOW: 'deposit_flow',
  DEPOSIT_PAID_FLOW: 'plan_a_trip_paid_flow',
  ...PROFILER_FLOWS,
};

/**
 * List of flow which allow customers to choose to "Continue with free membership"
 * instead of just abandoning te site.
 *
 * @type {Object|(string)[]}
 */
export const PREMIUM_FLOWS = [SIGNUP_FLOW.UPGRADE, SIGNUP_FLOW.PROFILER_UPGRADE];

export default ErrorBoundaryDecorator()(SIGNUP_FLOW);
