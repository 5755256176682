import React from 'react';
import PropTypes from 'prop-types';

import Spinner from './Spinner';

const OverlaySpinner = ({ dark }) => (
  <div className={`spinner-overlay ${dark ? 'dark-overlay' : 'light-overlay'}`} data-qa-id="spinner">
    <Spinner />
  </div>
);

OverlaySpinner.propTypes = {
  dark: PropTypes.bool,
};

OverlaySpinner.defaultProps = {
  dark: false,
};

export default OverlaySpinner;
