import React from 'react';
import { Form } from 'formik';
import PropTypes from 'prop-types';

import CustomField from '@components/formComponents/CustomField';
import ErrorBoundaryDecorator from '@components/decorators/ErrorBoundaryDecorator';
import { IMAGE_SIZES } from '@js/constants';
import Image from '@jsv2/components/Image';
import InputQuestion from './InputQuestion';
import SelectQuestion from './SelectQuestion';
import UploadQuestion from './UploadQuestion';
import DummyQuestion from './DummyQuestion';
import { QUESTION_TYPES } from '../config';

const component = {
  [QUESTION_TYPES.TEXT]: (props) => <InputQuestion {...props} />,
  [QUESTION_TYPES.TEXT_MULTIPLE]: (props) => <InputQuestion {...props} />,
  [QUESTION_TYPES.SELECT]: (props) => <SelectQuestion {...props} />,
  [QUESTION_TYPES.SELECT_MULTIPLE]: (props) => <SelectQuestion {...props} />,
  [QUESTION_TYPES.UPLOAD]: (props) => <UploadQuestion {...props} />,
  [QUESTION_TYPES.DUMMY]: (props) => <DummyQuestion {...props} />,
};

/**
 * The component to display all questions on one screen.
 *
 * @param {string} classNames
 * @param {string} contentClassNames
 * @param {array} questions
 * @param {string} error
 * @param {object} values
 * @param {object} fieldsErrors
 * @param {function} getQuestionActions
 * @param {function} getFormActions
 * @param {function} setQuestionClass
 * @param {function} uploadFile
 * @param {boolean} showQuestionImage
 * @return {JSX.Element}
 * @constructor
 */
const QuestionsForm = ({
  classNames,
  contentClassNames,
  questions,
  error,
  values,
  fieldsErrors,
  getQuestionActions,
  getFormActions,
  setQuestionClass,
  uploadFile,
  showQuestionImage,
}) => {
  /**
   * @param {object} item
   * @return {boolean}
   */
  const isTextQuestion = (item) =>
    item.type === QUESTION_TYPES.TEXT || item.type === QUESTION_TYPES.TEXT_MULTIPLE;

  /**
   * @param {object} item
   * @return {boolean}
   */
  const isUploadQuestion = (item) => item.type === QUESTION_TYPES.UPLOAD;

  /**
   * @param {object} item
   * @return {false|boolean|*}
   */
  const isCommentsAllowed = (item) => !isTextQuestion(item) && item.comment_allowed;

  /**
   * @param item
   * @return {*|string}
   */
  const renderQuestionTitle = (item) => {
    return item.question;
  };

  return (
    <Form className={classNames}>
      <div className="questionnaire-section">
        {questions.map((item, index) => (
          <div
            key={item.id}
            className={`quiz-wrapper ${
              isTextQuestion(item) || isUploadQuestion(item)
                ? 'quiz-wrapper--text'
                : 'quiz-wrapper--select'
            } ${setQuestionClass(item)}`}
            data-qa-id={`qa_question_${index + 1}`}
            data-required-field={item.is_required}
          >
            <div className={`layover-content ${contentClassNames}`}>
              {showQuestionImage && (
                <Image
                  className="quiz-question-image"
                  data={item.icon}
                  config={{
                    size: IMAGE_SIZES.SMALL,
                  }}
                  background
                />
              )}

              <div className="quiz-content">
                {item.type !== QUESTION_TYPES.DUMMY && (
                  <div className="question-title">{renderQuestionTitle(item)}</div>
                )}

                {component[item.type]({
                  question: item,
                  error,
                  questionIndex: index,
                  values,
                  uploadFile,
                })}

                {isCommentsAllowed(item) && (
                  <div className="questionnaire-comment form-field input mb-0">
                    <CustomField
                      name={`answers[${index}].comment`}
                      component="input"
                      placeholder={item.comment_placeholder}
                      autoComplete="off"
                    />
                  </div>
                )}

                {fieldsErrors[item.id] && (
                  <span className="error-message m-0 w-100 p-5 wide">{fieldsErrors[item.id]}</span>
                )}

                {getQuestionActions(item, index)}
              </div>
            </div>
          </div>
        ))}

        {getFormActions()}
      </div>
    </Form>
  );
};

QuestionsForm.propTypes = {
  questions: PropTypes.arrayOf(PropTypes.object).isRequired,
  error: PropTypes.string,
  classNames: PropTypes.string,
  contentClassNames: PropTypes.string,
  values: PropTypes.object.isRequired,
  fieldsErrors: PropTypes.object,
  getQuestionActions: PropTypes.func,
  getFormActions: PropTypes.func,
  setQuestionClass: PropTypes.func,
  uploadFile: PropTypes.func.isRequired,
  showQuestionImage: PropTypes.bool,
};

QuestionsForm.defaultProps = {
  error: '',
  classNames: '',
  contentClassNames: '',
  fieldsErrors: {},
  getQuestionActions: () => {},
  getFormActions: () => {},
  setQuestionClass: () => '',
  showQuestionImage: false,
};

export default ErrorBoundaryDecorator()(QuestionsForm);
